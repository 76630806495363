@import '../../../assets/sass/variables';

mat-form-field {
	width: 100%;
}

.form-column-layout {
	display: grid;
	grid-column-gap: 1.25rem;
	grid-template-columns: repeat(12, 1fr);
}

.form-column-layout > * {
	overflow-x: auto;
}

.span-2 {
	grid-column: auto / span 2;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 6;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-3 {
	grid-column: auto / span 3;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 6;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-4 {
	grid-column: auto / span 4;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 6;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-6 {
	grid-column: auto / span 6;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 12;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-7 {
	grid-column: auto / span 7;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 12;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-8 {
	grid-column: auto / span 8;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 12;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-9 {
	grid-column: auto / span 9;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 12;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-10 {
	grid-column: auto / span 10;

	@media only screen and (max-width: $bp-medium) {
		grid-column: auto / span 12;
	}

	@media only screen and (max-width: $bp-small) {
		grid-column: auto / span 12;
	}
}

.span-12 {
	grid-column: auto / span 12;
}

.input-field {
	margin-bottom: .5rem;
}

.form-footer {
	display: flex;
	justify-content: flex-end;
	margin-top: 1.5rem;

	button {
		margin: .5rem 0 .5rem .5rem;
	}
}

.checkbox, .slide-toggle {
	.mat-error {
		font-size: 75%;
	}

	.mat-hint {
		display: block;
		font-size: 75%;
	}
}

.mat-checkbox-layout, .mat-slide-toggle {
	padding: 1rem 0 1.18rem 0;
}

.mat-slide-toggle {
	display: block !important;
	height: auto !important;
}

.mat-slide-toggle-bar {
	margin-top: 5px;
	margin-bottom: auto;
}

/*Responzivita*/

@media screen and (max-width: 600px) {
	.form-footer {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		margin-top: 1.5rem;

		button {
			margin: 0.5rem 0.5rem 0.5rem 0.5rem;
		}
	}
}
/*Responzivita*/
