@import 'theme';
@import 'variables';
@import "assets/sass/components/forms";
@import "assets/sass/components/snack-bar";
@import "assets/sass/components/tables";
@import "assets/sass/components/dialog";

//  mixins
@import './assets/egov/assets/mixins/basic';
@import './assets/egov/assets/mixins/buttons';
@import './assets/egov/assets/mixins/placeholder';
@import './assets/egov/assets/mixins/pseudo';
@import './assets/egov/assets/mixins/unit';
@import './assets/egov/assets/mixins/z-index';

//  utils
@import './assets/egov/source/css/styles/utils/u-background';

html:not([probihajici-pozadavky]) .global-spinner {
	display: none;
}

html, body {
	height: 100%;
}

body {
	background-color: $color-vvz-grey-background;
	color: $color-vvz-grey-dark !important;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	margin: 0;
	padding: 0;
}

th {
	z-index: 1 !important;
}

.container {
	margin: 2rem auto;
	max-width: $max-container-width;

	&__content {
		background-color: $color-white;
		padding: 1.5rem;

		@media only screen and (max-width: $bp-medium) {
			margin: 0;
		}
	}
}

.gov-footer__scroll-up {
	top: -51px !important;
}

// Vypina borderline egov uvnitr mat form inputs daneho type
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus,
[type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus, textarea:focus {
	border-color: unset;
	box-shadow: none !important;
	outline: 0
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	transform: translateY(-0.99375em) scale(0.95) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	top: 0 !important;
}

.mat-form-field-appearance-outline {
	.mat-form-field-outline-gap {
		width: 0 !important;
	}
}

.mat-form-field-infix {
	border-top: none;

	input.mat-input-element, mat-select.mat-select {
		margin-top: 0.3em;
	}
}

.mat-form-field-appearance-outline .mat-form-field-flex {
	padding: 0.8em 0.75em 0.3em 0.75em !important;
}

//FIXED_BUGS
.mat-checkbox-layout, .mat-slide-toggle-content {
	white-space: normal !important;
}

.mat-checkbox-inner-container {
	margin-top: 3px !important;
}

.mat-standard-chip {
	height: unset !important;
	word-break: break-word;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: rgba(0, 0, 0, 0.3);
}

.mat-form-field-appearance-outline {
	&.mat-focused {
		.mat-form-field-outline-thick {
			color: $color-vvz-blue-focus !important;
		}
	}
}

.mat-form-field-label-wrapper .mat-form-field-label {
	line-height: 20px;
}

.mat-form-field-disabled .mat-form-field-outline {
	background-color: rgba(0, 0, 0, 0.03);
}

.mat-input-element:disabled, .mat-select-disabled .mat-select-value, .mat-checkbox-disabled .mat-checkbox-label {
	color: rgba(0, 0, 0, 0.65) !important;
}

.mat-dialog-container app-frontend-datova-tabulka,
.mat-dialog-container app-backend-datova-tabulka {
	display: block;
	width: 100%;
	padding-bottom: 1rem;
}

.mat-elevation-z8 {
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, .2);
	padding: 2px 2px 2px 0;
	border-radius: 5px;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
	box-shadow: none !important;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, .2);
	padding: 2px 2px 2px 0;
	border-radius: 5px;
}

.mat-button {
	&.btn-primary-blue {
		background-color: #2362a2;
		color: white;

		&:hover {
			background-color: #254E80 !important;
			color: white;
		}

		&:focus {
			background-color: #2362a2;
			outline: 2px solid #006FE6;
			outline-offset: 1px;
		}

		&:disabled {
			background-color: #D3DFEC;
		}
	}
}

.mat-button-base {
	&.btn-secondary-white {
		background-color: white;
		color: #2362A2;
		border: 1px solid #2362A2;

		&:hover {
			background-color: #e5ebf0 !important;
			background-color: var(--gov-color-blue-hover) !important;
		}

		&:focus {
			outline: 2px solid #006FE6;
			background-color: transparent;
		}

		&:disabled {
			background-color: transparent;
			opacity: 30%;
		}
	}
}

.mat-paginator-container .mat-icon-button svg {
	fill: #2362a2;
}

.mat-paginator-container .mat-icon-button.mat-button-disabled.mat-button-disabled svg {
	fill: #2362a2;
	opacity: 30%;
}

.mat-chip.mat-standard-chip {
	background-color: rgba(35, 98, 162, 0.5);
}

.mat-form-field-appearance-outline .mat-form-field-suffix {
	top: -.15em !important;
	place-self: center;

	.mat-icon {
		cursor: pointer;
	}
}

.table-container {
	th {
		text-transform: uppercase;
		color: #2362a2;
		font-size: 14px;
	}

	.mat-sort-header-pointer-left, .mat-sort-header-pointer-right {
		background-color: #2362a2;
	}

	.mat-sort-header-arrow {
		color: #2362a2;
	}
}

//DatePicker

table tbody tr, .gov-table tbody tr {
	border: none !important;
}

table tbody td, .gov-table tbody td {
	margin-bottom: 0;
	min-width: 0 !important;
	border: none
}

.mat-datepicker-content {
	background-color: $color-vvz-blue-light;
	box-shadow: none;
	color: #ffffff;

	.mat-calendar-table-header {
		th {
			color: #ffffff;
		}
	}

	.mat-calendar-table-header, .mat-calendar-body-label {
		color: #ffffff;
	}

	.mat-calendar-table-header-divider:after {
		background-color: #ffffff;
	}

	.mat-calendar-controls {
		.mat-calendar-previous-button:hover, .mat-calendar-next-button:hover {
			background-color: $color-vvz-blue !important;
			outline: 2px solid #ffffff;
			outline-offset: -2px;
			border-radius: 5px;
		}

		.mat-calendar-period-button:hover {
			background-color: $color-vvz-blue !important;
		}

		button {
			color: #ffffff;
		}

		svg {
			fill: #ffffff;
		}
	}

	.mat-calendar-body-cell-content {
		border-radius: 0;
	}

	.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
		background-color: transparent !important;
		border: 2px solid #ffffff !important;
		border-radius: 5px !important;
		color: white !important;
	}

	.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
		border-color: $color-vvz-blue !important;
		color: #ffffff !important;
		background-color: #254E80 !important;
		border-radius: 5px !important;
	}

	.mat-calendar-body-selected {
		color: #ffffff !important;
		background-color: #254E80;
		border: 2px solid #ffffff;
		border-radius: 5px;
	}

	.mat-calendar-body-cell-content, .mat-date-range-input-separator {
		color: #ffffff;
	}
}

.ngx-mat-timepicker {
	.mat-focus-indicator, .mat-stroked-button:not([class*="mat-elevation-z"]), .mat-flat-button:not([class*="mat-elevation-z"]) {
		color: #ffffff;

		&:hover {
			color: #ffffff !important;
		}
	}

	td {
		background-color: $color-vvz-blue-light;
		box-shadow: none;
		color: #ffffff;
	}
}

@media not all and (pointer: coarse) {
	button:hover {
		background-color: transparent !important;
	}
}

//Ostatní
.gov-header__left {
	.gov-footer__image-link:after {
		display: none;
	}

	img {
		margin-right: 25px;
	}
}
