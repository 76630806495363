.table-container-container {
	position: relative;
}

.table-container {
	position: relative;
	overflow: auto;
}

table {
	width: 100%;
}

th:not(:first-child), td:not(:first-child) {
	padding-left: 6px !important;
}

th:not(:last-child), td:not(:last-child) {
	padding-right: 6px !important;
}

.table-loading-clear {
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
}

.table-loading-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 56px;
	right: 0;
	background: rgba(0, 0, 0, 0.15);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-bottom-buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;

	button {
		margin: .5rem 0 .5rem .5rem;
	}
}
