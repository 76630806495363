@use '@angular/material' as mat;

//GLOBAL_CSS_SETTINGS
$max-container-width: 1300px;

//FONT_VARIABLES
$font-weight-bold: 700;
$font-weight-medium: 500;

$font-size-x-large: 1.5rem;	//24px
$font-size-large: 1.375rem;	//22px
$font-size-normal: 1rem;	//16px
$font-size-small: .875rem;	//14px

//COLOR_VARIABLES
$color-black: black;
$color-white: white;

$color-vvz-blue: #2362a2;
$color-vvz-blue-hover: #e5ebf0;
$color-vvz-blue-inactive: #edf0f2;
$color-vvz-blue-light: #3077b7;
$color-vvz-blue-focus: #007bff;

$color-vvz-grey-background: #f5f5f5;
$color-vvz-grey-dark: #3b3b3b;
$color-vvz-grey-dark-mid: #686868;

$color-vvz-error: #c52a3a;
$color-vvz-information: #007bff;
$color-vvz-success: #6fbd2c;
$color-vvz-warning: #ecae1a;

//RESPONSIVE_BREAKPOINTS
$bp-largest: 100rem;	//1600px
$bp-large: 80rem;		//1280px
$bp-medium: 60rem;		//960px
$bp-small: 37.5rem;		//600px
$bp-smallest: 30rem;	//480px
